import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import withRouter from 'with-router';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TranslateOutlined from '@material-ui/icons/TranslateOutlined';
import InputOutlined from '@material-ui/icons/InputOutlined';
import EditOutlined from '@material-ui/icons/EditOutlined';
import CircularProgress from '@material-ui/core/CircularProgress';
import Fab from '@material-ui/core/Fab';
import { Trans, withTranslation, useTranslation } from 'react-i18next';
import { SnackbarContent, withSnackbar } from 'notistack';
import { Alert } from '@material-ui/lab';

import $ from 'jquery';
import Redactor from './RedactorWrapper';
import RedactorX from './RedactorXWrapper';
import ErrorBoundary from './ErrorBoundary';
import { UpdateAppPermissionsSnackbar } from './UpdateAppPermissionsDialog';
import API from '../api/api';

class RichTextEditor extends React.Component {
  self = this;

  constructor(props) {
    super(props);


    this.state = {
      value: props.value,
      toolbarRowsClass: 'toolbar-rows-1',
      sourceMode: false,
      loading: false,
      focused: false,
    };

    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    window.$ = window.jQuery = $;
  }

  componentWillUnmount() {
    $(window).unbind('resize', this.checkResize);
  }

  componentWillReceiveProps(newProps) {
    if(this.props.value !== newProps.value) {
      this.setState({
        value: newProps.value,
      });
    }
    if(this.props.focused !== newProps.focused) {
      this.setState({
        focused: newProps.focused,
      });
    }
  }

  onStarted = () => {
    this.props.onLoaded && this.props.onLoaded();
  }

  onChange = (data) => {

    if(!this.props.isPending && data !== this.props.value) {
      this.props.onChange({
        target: {
          value: data
        }
      });
    }
  }

  onFocus = (evt) => {
    let rows, $editor, $toolbar;
    if(this.props.version === 'v2') 
    { // REDACTORX
      $editor = window.RedactorX(`#${this.props.id}`);
      $editor.toolbar.setActive();
      //if(this.props.id.indexOf('source') !== -1) {
        //let $sourceEditor = window.RedactorX(`#${this.props.id}`);
        //$sourceEditor?.editor?.setBlur();
      //}
    } 
    else
    { // REDACTOR
      $toolbar = $(`#${this.props.id}-toolbar`);
      $editor = $(`#${this.props.id}`);
      $toolbar.addClass('in');
      rows = Math.floor($toolbar.height() / $toolbar.find('.re-html').outerHeight());
      $editor.addClass('toolbar-rows-' + rows);
      this.setState({
        toolbarRowsClass: `toolbar-rows-${rows}`,
      });
    }
  };

  onBlur = (evt) => {
    let isSourceMode, isEmpty;
    if(this.props.version === 'v2') 
    { // REDACTORX
      //let $editor = window.RedactorX(`#${this.props.id}`);
      //isSourceMode = $editor.source.is();
      //isEmpty = $editor.editor.isEmpty();
      //$editor.toolbar.unsetActive();
    } 
    else
    { // REDACTOR
      isSourceMode = $(`#${this.props.id}`).redactor('editor.isSourceMode');
      isEmpty = $(`#${this.props.id}`).redactor('editor.isEmpty');
      if(!isSourceMode) {
        let $toolbar = $(`#${this.props.id}-toolbar`);
        $toolbar.removeClass('in');
      }
    }    
  };

  onSourceOpened = (evt) => {
    if(this.props.id.indexOf('target') !== -1) {
      let newId = this.props.id.replace('target', 'source');

      if(this.props.version === 'v2') 
      { // REDACTORX
        let $sourceEditor = window.RedactorX(`#${newId}`);
        let $targetEditor = window.RedactorX(`#${this.props.id}`);
        if($sourceEditor) {
          $sourceEditor.source.open();
          $sourceEditor.editor.setBlur();
          $targetEditor.container.setFocus();
          $targetEditor.editor.setFocus('start');
        }
      }
      else
      { // REDACTOR
        let $sourceEditor = $(`#${newId}`);
        if($sourceEditor.length > 0) {
          $sourceEditor.attr('disabled', 'disabled');
          $sourceEditor.redactor('disableReadOnly');
          $sourceEditor.redactor('module.source.show');
          $sourceEditor.redactor('enableReadOnly');        
        }
      }
    }
    this.setState({
      sourceMode: true,
    });
  };

  onSourceClosed = (evt) => {
    let $sourceEditor;
    let $targetEditor;
    if(this.props.id.indexOf('target') !== -1) {
      let newId = this.props.id.replace('target', 'source');
      if(this.props.version === 'v2')
      { // REDACTORX
        $sourceEditor = window.RedactorX(`#${newId}`);
        $targetEditor = window.RedactorX(`#${this.props.id}`);
        if($sourceEditor) {
          $sourceEditor.source.close();
          $targetEditor.container.setFocus();
          $targetEditor.editor.setFocus('start');
          //$sourceEditor.editor.setBlur();
        }      
      }
      else
      { // REDACTOR
        $sourceEditor = $(`#${newId}`);
        if($sourceEditor.length > 0) {
          $sourceEditor.redactor('disableReadOnly');
          $sourceEditor.redactor('module.source.hide');
          $sourceEditor.redactor('enableReadOnly');        
        }
      }

    }
    this.setState({
      sourceMode: false,
    });
  };




  onUseImageUpload = () => {
    // Hier Snackbar triggern, wenn die app keine schreib und leserechte hat!

    if(this.props.access_rights && this.props.access_rights.access_lack && this.props.access_rights.access_lack.length > 0) {
      this.props.enqueueSnackbar('In order to use the upload feature, you have to grand langify more permissions.', {
        variant: 'error',
        persist: true,
        //action: this.onUpdate,
        //hideIconVariant: true,
        //iconVariant: <ErrorOutlineOutlinedIcon />,
        content: (key, message) => <UpdateAppPermissionsSnackbar
          message={message}
        />
      });
    }
  }

  checkResize = (e) => {
    let $toolbar = $(`#${this.props.id}-toolbar`);
    let rows = Math.floor($toolbar.height() / $toolbar.find('.re-html').outerHeight());
    if(undefined !== rows) {
      this.setState({
        toolbarRowsClass: `toolbar-rows-${rows}`
      });
    }
  };

  render() {
    const { 
      classes, 
      densed,
      id, 
      config,
      readOnly,
      hasImportOriginalBtn,
      hasAutoTranslateBtn,
      label,
      onChange,
      error,
      forceUpdate,
      triggerDiscardChanges
    } = this.props;

    const { 
      value,
      toolbarRowsClass, 
      sourceMode,
      loading,
      focused
    } = this.state;

    const ErroMessage = <div className={classes.errorRoot}>
      <textarea className={classNames(classes.errorValue, !onChange ? 'readonly' : '')} value={value} onChange={(e) => onChange ? this.onChange(e.target.value) : null} readOnly={!onChange} />
      <p className={classes.errorNotice}>NOTICE: The richtext editor could not load, due to an invalid HTML structure in the content.</p>
    </div>

    const redactorProps = {
      id: id,
      defaultValue: value,
      config: config,
      readOnly: readOnly,
      focused: focused,
      onStarted: this.onStarted,
      onFocus: this.onFocus,
      onBlur: this.onBlur,
      onChange: onChange ? this.onChange : null,
      onSourceOpened: this.onSourceOpened,
      onSourceClosed: this.onSourceClosed,
      onUseImageUpload: this.onUseImageUpload
    }

    return (
      <ErrorBoundary msg={ErroMessage}>
        <div className={classNames(
          'ly-editor', 
          readOnly ? 'read-only' : toolbarRowsClass,
          sourceMode ? 'source-mode' : '',
          densed ? 'densed' : '',
          classes.root, 
          this.props.version === 'v2' ? '_redactorx' : '_redactor',
          error ? 'error' : false,
        )}>
          <div className={classes.outlineContainer}>
            <div className={classNames(classes.placeholderBox)} />      

            {label &&
              <Typography className={classNames('ly-editor-label', classes.label)} variant="caption" gutterBottom>
                {label}
              </Typography>          
            }
            
            {(!this.props.isPending) &&
              <React.Fragment>
                {this.props.version === 'v2' ?
                  <RedactorX {...redactorProps} forceUpdate={forceUpdate} triggerDiscardChanges={triggerDiscardChanges}/>
                :
                  <Redactor {...redactorProps} />
                } 
                {loading &&
                  <CircularProgress style={{color: '#000', position: 'absolute', left: '50%', top: '50%', margin: '-20px 0 0 -20px'}} />  
                }          
              </React.Fragment>
            }

          </div>
        </div>
      </ErrorBoundary>
    )
  }
}

const styles = theme => ({
  root: {
    position: 'relative',
    minHeight: 300,
    //margin: '5px 0',
  },
  placeholderBox: {
    border: 'solid 1px rgba(0, 0, 0, 0.26)',
    borderRadius: 4,
    height: '100%',
    position: 'absolute',
    width: '100%',
    zIndex: -1,
  },
  outlineContainer: {

  },
  label: {
    position: 'absolute',
    zIndex: 1,
    left: 10,
    top: -8,
    display: 'inline-block',
    //backgroundColor: '#fff',
    padding: '0 4px',
    color: 'rgba(0, 0, 0, 0.38)'
  },
  editor: {
    backgroundColor: '#fff', 
    padding: '0 8px', 
    margin: 0, 
    height: 300 
  },
  buttonLayer: {
    position: 'absolute',
    margin: 'auto',
    zIndex: 9,
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: 250,
    height: 65,
    textAlign: 'center'
  },
  autoTranslateBtn: {
    width: 200,
  },
  importOriginalBtn: {
    marginTop: 8,
    width: 200,
  },
  margin: {
    margin: 6
  },
  errorRoot: {
    border: 'solid 1px rgba(0, 0, 0, 0.26)',
    borderRadius: 4,
    minHeight: 300,
    position: 'relative',
    width: '100%',
    overflow: 'hidden'
  },
  errorValue: {
    padding: '0 8px',
    color: 'rgba(0, 0, 0, 0.87)',
    minHeight: 300,
    width: '100%',
    overflow: 'scroll',
    border: 'none',
    '&.readonly': {
      color: 'rgba(0, 0, 0, 0.38)',
    }
  },
  errorNotice: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    margin: 4,
    padding: '8px 8px',
    color: '#999',
    backgroundColor: '#eee',
    border: 'solid 1px #ddd',
    borderRadius: 3,
  },

  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
});

RichTextEditor.defaultProps = {
  value: '',
  isPending: false
};

RichTextEditor.propTypes = {
  value: PropTypes.string,
  isPending: PropTypes.bool
};


const mapStateToProps = ({ shop, themes, languages }) => ({
  shop: shop,
  themes: themes,
  languages: languages,
  editLanguage: shop.shop.edit_language,
  access_rights: shop.shop.access_rights,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
    },
    dispatch
  )

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withTranslation()(withSnackbar(RichTextEditor)))));