import React, { Component } from 'react';
import withRouter from 'with-router';
import { Route, Prompt, Link } from 'react-router-dom';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import $ from 'jquery';
import _ from 'lodash';
import API from '../api/api';
import ErrorBoundary from './ErrorBoundary';
import {Controlled as CodeMirror} from 'react-codemirror2';
import { Trans, withTranslation, useTranslation } from 'react-i18next';
import { SnackbarContent, withSnackbar } from 'notistack';
import { Alert } from '@material-ui/lab';

window.$ = window.jQuery = $;

class RedactorX extends React.Component {
  app;

  constructor(props) {
    super(props);

    this.state = {
      textareaKey: `richtext-${this.props.id}-${this.props.defaultValue}`,
    };

    // Referenz für das Textarea-Element
    this.textareaRef = React.createRef();
  }

  componentDidMount() {
    let root = this;

    const {
      id,
      readOnly,
      focused,
      onStarted,
      onFocus,
      onBlur,
      onKeydown,
      onKeyup,
      onSourceOpened,
      onSourceClosed,
      onSourceChanged,
      onEditorDestroyed,
      i18n,
      forceUpdate
    } = this.props;

    const defaultConfig = {
      plugins: ['alignment', 'imageposition', 'imageresize', 'removeformat', 'underline'],
      buttons: {
        addbar: ['paragraph', 'table', 'image', 'embed', 'quote', 'pre', 'line'],
        editor: ['add', 'format'],
        context: ['bold', 'italic', 'link'],
        toolbar: [],
        topbar: ['html'],
      },
      addbar: {
        add: [],
        hide: ['quote', 'pre'],
      },
      toolbar: {
        hide: ['html', 'deleted'],
      },
      editor: {
        focus: focused,
        notranslate: true,
        maxHeight: '350px',
        minHeight: '350px',
        lang: i18n.language
      },
      format: ['p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'ul', 'ol'],
      tags: {
        denied: []
      },
      line: false,
      clean: {
        enterinline: true
      },
      paste: {
        clean: false,
        autoparse: false,
        paragraphize: false,
      },
      image: {
        upload: function(upload, data) {
          var formData = new FormData();        
          var file = data.files[0];
          formData.append('image', file);
          //console.log(file);

          API.uploadMedia(formData, response => {
            //console.log(response)
            if(response.success == true) {
              var res = {
                "file": {
                    "url": response.url,
                    "id": response.url
                }
              };
              upload.complete(res, data.e);
            } else {
              upload.complete(response);
            }
          });
        }
      },
      codemirror: {
        lineNumbers: true,
        readOnly: readOnly ? true : false,
        mode: 'html',
        theme: 'elegant',
        tabSize: 0,
        specialChars: /[\u0000-\u001f\u007f-\u009f\u00ad\u061c\u200b\u200e\u200f\u2028\u2029\u202d\u202e\u2066\u2067\u2069\ufeff\ufff9-\ufffc]/g,
      },
      subscribe: {
        'editor.blur': onBlur ? onBlur : null,
        'editor.focus': onFocus ? onFocus : null,
        'editor.before.parse': function(event) {
          var content = event.get('html');
          //if(!readOnly) console.log('\n\n\neditor.before.parse (#'+ id +')\n------------------------------------\n', content);
        },
        'editor.parse': function(event) {
          var content = event.get('html');
          //if(!readOnly) console.log('\n\n\neditor.parse (#'+ id +')\n------------------------------------\n', content);
          event.set('html', content);
        },
        'editor.unparse': function(event) {
          var content = event.get('html');
          var newHtml = '';
          if(root._isHtml(content)) {
            var $nodes = this.dom(content);
            var nodes = $nodes.getAll();

            for (var i = 0; i < nodes.length; i++) {
              //if(!readOnly) console.log(nodes[i]);
              var node = nodes[i];
              var $node = this.dom(nodes[i]);
              if(node.nodeType === 8) {
                newHtml += '<!--'+ node.textContent +'-->';
              } else if ($node.hasClass('langify-figure') || $node.attr('data-paragraphized')) {
                newHtml += $node.html();
              } else {
                newHtml += node.outerHTML || node.nodeValue;
              }
            }            
          } else {
            newHtml = content;
          }
          

          newHtml = newHtml
            .replace('<meta charset="utf-8">', '')
            .replace('<meta type="rx-editor">', '');
          //if(!readOnly) console.log('\n\n\neditor.unparse (#'+ id +')\n------------------------------------\n', newHtml);

          event.set('html', newHtml);
        },
        'editor.before.insert': function(event) {
          //if(!readOnly) console.log('\n\n\neditor.before.insert\n------------------------------------\n', event);
          var instance = event.get('instance');
        },
        'editor.insert': function(event) {
          //if(!readOnly) console.log('\n\n\neditor.insert\n------------------------------------\n', event);
          var instance = event.get('instance');
        },
        'editor.change': this.handleChange,
        'editor.mousedown': function(event) {
          if(!this.app.editor.isFocus()) {
            this.app.editor.setFocus('start');
          }
        },
        'source.change': this.handleSourceChange,
        'source.open': onSourceOpened ? onSourceOpened : null,
        'source.close': onSourceClosed ? onSourceClosed : null,
        'popup.open': function() {
          var name = this.app.popup.getName();
          if(name === 'image') {
            root.props.onUseImageUpload();
          }
        },
      },
    };

    this.config = _.merge(defaultConfig, this.props.config);
    this.app = window.RedactorX('#' + id, this.config);

    // Initial Focus (fix for redactorX bug)
    if (this.props.focused) {
      let $editor = this.app.container.get('main');
      let editor = $editor.get();
      editor.classList.toggle('focused');
    }
  }

  componentWillUnmount() {
    if(this.app) {
      this.app.destroy();
    }
  }

  componentWillReceiveProps(newProps) {
    
    // Enforces a rerendering of the RedactorX editor.
    if(newProps.forceUpdate === false && this.props.forceUpdate === true) {
      this.app.editor.setContent({ html: newProps.defaultValue });
    }

    if(newProps.triggerDiscardChanges != this.props.triggerDiscardChanges) {
      this.app.editor.setContent({ html: newProps.defaultValue });
    }
  }

  handleChange = (event) => {
    // Hier können wir den Inhalt des Textarea-Elements abrufen
    const content = this.textareaRef.current.value;
    if (this.props.onChange) {
      this.props.onChange(content);
    }
  }

  handleSourceChange = (event) => {
    const content = this.textareaRef.current.value;
    if (this.props.onSourceChanged) {
      this.props.onSourceChanged(content);
    }
  }

  render() {
    return (
      <textarea 
        id={`${this.props.id}`} 
        //key={this.state.textareaKey}
        ref={this.textareaRef}
        defaultValue={this.props.defaultValue}
        onChange={this.handleChange}
      />
    )
  }

  _isHtml(content) {
    const doc = new DOMParser().parseFromString(content, 'text/html');
    return Array.from(doc.body.childNodes).some(node => node.nodeType === Node.ELEMENT_NODE);
  }
}

export default withTranslation()(withSnackbar(RedactorX));