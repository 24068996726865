import React, { useState } from 'react';
import ReactDOM from "react-dom";
import { connect } from 'react-redux';
import withRouter from 'with-router';
import { Route, Prompt, Link } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { bindActionCreators } from 'redux'
import TablePagination from '@material-ui/core/TablePagination';
import TablePaginationActions from '@material-ui/core/TablePagination/TablePaginationActions';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import InputBase from '@material-ui/core/InputBase';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FilterListIcon from '@material-ui/icons/FilterList';
import Chip from '@material-ui/core/Chip';
import Select from '@material-ui/core/Select';
import SortSharpIcon from '@material-ui/icons/SortSharp';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ArrowBackOutlined from '@material-ui/icons/ArrowBackOutlined';
import ArrowForwardOutlined from '@material-ui/icons/ArrowForwardOutlined';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ExpandMore from '@material-ui/icons/ExpandMore';
import CloseIcon from '@material-ui/icons/Close';
import MenuIcon from '@material-ui/icons/Menu';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import SaveAltOutlinedIcon from '@material-ui/icons/SaveAltOutlined';
import WarningOutlinedIcon from '@material-ui/icons/WarningOutlined';
import Divider from '@material-ui/core/Divider';
import Sticky from 'react-sticky-el';
import TranslationFields from './TranslationFields';
import { SaveErrorsList, SaveInfosList } from './SnackbarItems';
import ProgressToolbar from './ProgressToolbar.js';
import ProgressIndicator from './ProgressIndicator.js';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import red from '@material-ui/core/colors/red';
import ResaveIcon from '@material-ui/icons/Replay';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import CircularProgress from '@material-ui/core/CircularProgress';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import StopIcon from '@material-ui/icons/Stop';
import AddIcon from '@material-ui/icons/Add';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import { Trans, withTranslation, useTranslation } from 'react-i18next';
import { Alert } from '@material-ui/lab';
import { SnackbarContent, withSnackbar } from 'notistack';
import { parseItem2FieldIdsPath, parseItemId } from '../helper';
import ErrorBoundary from './ErrorBoundary'

import _ from 'lodash';

const REACT_APP_ITEMSLIST_OPENING_ANIMATION = window.loadEnv("REACT_APP_ITEMSLIST_OPENING_ANIMATION", process.env.REACT_APP_ITEMSLIST_OPENING_ANIMATION);

// Sorting
function desc(a, b, orderBy) {
  const varA = (typeof a[orderBy] === 'string') ? a[orderBy].toLowerCase() : a[orderBy];
  const varB = (typeof b[orderBy] === 'string') ? b[orderBy].toLowerCase() : b[orderBy];
  if (varB < varA) {
    return -1;
  }
  if (varB > varA) {
    return 1;
  }
  return 0;
}
function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}
function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

// Filtering
function filterItems(array, query, group) {
  let returnedArray = array;
  

  // Step 1: Filter for field-groups like "outdated" handling
  if(group) {
    returnedArray = returnedArray.filter(el => {
      let inFieldsCheck = false;
      if(el.fields) {
        for (let i = 0; i < el.fields.length; i++) {
          if(el.fields[i].type != 'subfields' && el.fields[i].type != 'image') {

            // show items with true "outdated" field
            if(group === 'outdated') {
              if(el.fields[i].outdated && el.fields[i].outdated === true) {
                inFieldsCheck = true;
                break;
              }
            }

            //
            //
            // Add new conditions here
            // ...

            // show all items
            if(group && group === 'all') {
              inFieldsCheck = true;
              break;
            }
          }
        }
      }
      return inFieldsCheck;
    });    
  }

  

  // Step 2: Filter query string handling
  returnedArray = returnedArray.filter(el => {
    let inFieldsCheck = false;
    if(el.fields) {
      for (let i = 0; i < el.fields.length; i++) {
        if(el.fields[i].type != 'subfields' && el.fields[i].type != 'image') {

          
          // Filter in this fields:
          const fields = [
            // Check if this field is present in window.restoreFieldsData[`${id}${parentId}-${data.id}`]
            // When it is, also add the item's value here in order that the field isn't filtered and disappears when you filter for a target text which is beeing altered.
            window.restoreFieldsData[`${el.id}-${el.fields[i].id}`] || null, 
            
            el.fields[i].source,
            el.fields[i].target ? el.fields[i].target : null,
            window.location.href.indexOf('/theme') > -1 ? el.fields[i].label : null,
          ];
          if(fields.join(' ').toLowerCase().indexOf(query.toLowerCase()) > -1) {
            inFieldsCheck = true;
            break;
          }
        }
      }      
    }
    return el.title.concat(el.shopify_id).toLowerCase().indexOf(query.toLowerCase()) > -1 || inFieldsCheck
  });

  return returnedArray;
}

// Pagination
function paginateItems(array, paginate, page, rowsPerPage) {
  if(paginate) {
    return array.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
  } else {
    return array
  }
}

class ItemsListToolbar extends React.Component {
  state = {
    anchorEl: null,
    sorting: localStorage.getItem('ly_orderBy') ? `${localStorage.getItem('ly_orderBy')}${localStorage.getItem('ly_order')}` : 'titleasc',
    filterQuery: '',
    filterGroup: 'all',
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleSort = (field, order) => {
    this.handleClose();
    this.setState({sorting: `${field}${order}`})
    this.props.onSort(field, order);
  }

  handleFilterGroup = (group) => {
    this.handleClose();
    this.setState({filterGroup: group})
    this.props.onFilterGroup(group);
  }

  onFilterChange = (event) => {
    this.setState({ filterQuery: event.target.value })
    if(this.props.onQueryChange) {
      this.props.onQueryChange(event);
    }
  }

  onDiscard = (event) => {
    if(this.props.onDiscard) {
      this.props.onDiscard(event);
    }
  }

  onResetQuery = () => {
    this.onFilterChange({ 
      target: {
        value: ''
      } 
    });
  }

  onRemoveFilterGroup = (event) => {
    this.handleFilterGroup('all');
  }


  render() {
    const { t, data, numSelected, isSticky, hideFilterbar, hideSorting, allowGroupFiltering, toolbarTitle, classes } = this.props;
    const { filterQuery, filterGroup, anchorEl, sorting } = this.state;

    return (
      <React.Fragment>

        {numSelected > 0 &&
          <React.Fragment>
            <Toolbar className={classNames(classes.root, classes.actionBar, {
              [classes.highlight]: numSelected > 0,
            })} elevation={0}>
              <IconButton color="inherit" aria-label="Open drawer"
                onClick={this.handleDrawerOpen}
                className={classNames(
                  classes.menuButton,
                  this.state.open && classes.hidden,
                )}>
                <MenuIcon />
              </IconButton>

              <Typography component="h1" variant="h6" color="inherit" noWrap
                className={classNames(classes.appBarLogo, this.state.open && classes.hidden)}>
                <img className="logo-img" src="/langify-logo-secondary.png" style={{width: 110, marginTop: -2}} alt={"logo"}/>
              </Typography>
              <Typography color="inherit" variant="h6" style={{fontSize: '1.1rem', fontWeight: 400}}>
                {numSelected} {numSelected>1 ? t('itemsList.unsavedSections') : t('itemsList.unsavedSection')}
              </Typography>
              <div className={classes.actions}>
                <Button aria-label="Cancel" variant="outlined" color="default" size="medium" onClick={this.props.onDiscard} disabled={this.props.isPending} style={{marginRight: 8}}>
                  {t('system.discard')}
                </Button>
                <Button aria-label="Save All" color="secondary" variant="contained" size="medium" onClick={this.props.onSave} disabled={this.props.isPending}>
                  {t('system.save')} <SaveAltOutlinedIcon style={{marginLeft: 5}} />
                </Button>
              </div>
              <Divider className={classes.divider} />
            </Toolbar>
          </React.Fragment>
        }


        <Sticky
          scrollElement="#mainFrame"   
          topOffset={-64}
          disabled={!isSticky}
          stickyClassName={classes.sticky}
          hideOnBoundaryHit={true}
          positionRecheckInterval={100}
        >
          <Toolbar
            className={classNames(classes.root)}
          >
            {!this.props.filterModules && <div className={classes.title}>
              <React.Fragment>
                  {hideFilterbar ?
                    <Typography variant="subtitle1" style={{ color: '#999' }}>
                      {toolbarTitle ? toolbarTitle : 'Metadata'}
                    </Typography>
                  :
                    <React.Fragment>
                      <InputBase
                        style={{ margin: 0 }}
                        onChange={this.onFilterChange}
                        value={filterQuery}
                        placeholder={t('itemsList.filterPlaceholder')}
                        fullWidth
                        startAdornment={<SearchOutlinedIcon style={{opacity: .5, marginRight:10}} />}
                      />
                      {filterQuery &&
                        <span className={classNames(classes.closeIcon)}>
                          <CloseIcon onClick={this.onResetQuery}/>
                        </span>
                      }
                    </React.Fragment>
                  }
                </React.Fragment>
            </div>}
            {!this.props.filterModules && <div className={classes.spacer} />}
            <div className={classes.actions} 
              style={{
                width: this.props.filterModules ? '100%' : 'auto',
                display: this.props.filterModules ? 'flex' : 'inline-block',
              }}
            >

              {this.props.filterModules && 
                this.props.filterModules.map((module, index) => {
                  return module;
                })
              }

              {!this.props.filterModules && allowGroupFiltering &&
                <span style={{position: 'relative', marginLeft: 8}}>
                  {(filterGroup && filterGroup !== 'all') &&
                    <Chip color="secondary" size="medium" label={filterGroup} onDelete={this.onRemoveFilterGroup} className={classes.filterChip} />
                  }
                  <Tooltip title={t('itemsList.filterFieldTooltip')} disableTouchListener disableFocusListener arrow>
                    <IconButton 
                      aria-owns={'filter-menu'}
                      aria-haspopup="true"
                      aria-label={t('itemsList.filterFieldTooltip')}
                      onClick={this.handleClick}
                      style={{ borderRadius: 0}}
                    >
                      <FilterListIcon />
                    </IconButton>
                  </Tooltip>
                  <Menu
                    id="filter-menu"
                    anchorEl={anchorEl}
                    open={anchorEl && anchorEl.getAttribute('aria-owns') === 'filter-menu'}
                    onClose={this.handleClose}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    classes={{
                      paper: classes.sortMenu,
                    }}
                    disableAutoFocusItem
                  >
                    <MenuItem selected={(filterGroup === 'all')} onClick={() => this.handleFilterGroup('all')}><Trans i18nKey={'itemsList.filterAll'}></Trans></MenuItem>
                    <MenuItem selected={(filterGroup === 'outdated')} onClick={() => this.handleFilterGroup('outdated')}><Trans i18nKey={'itemsList.filterOutdated'}></Trans></MenuItem>
                  </Menu>                
                </span>
              }
              {!this.props.filterModules && !hideSorting &&
                <span style={{position: 'relative', marginLeft: 8}}>
                  <Tooltip title={t('itemsList.sortFieldTooltip')} disableTouchListener disableFocusListener arrow>
                    <IconButton 
                      aria-owns={'sort-menu'}
                      aria-haspopup="true"
                      aria-label={t('itemsList.sortFieldTooltip')}
                      onClick={this.handleClick}
                    >
                      <SortSharpIcon />
                    </IconButton>
                  </Tooltip>
                  <Menu
                    id="sort-menu"
                    anchorEl={anchorEl}
                    open={(anchorEl && anchorEl.getAttribute('aria-owns') === 'sort-menu') ? true : false}
                    onClose={this.handleClose}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    classes={{
                      paper: classes.sortMenu,
                    }}
                    disableAutoFocusItem
                  >
                    <MenuItem selected={(sorting === 'titleasc')} onClick={() => this.handleSort('title', 'asc')}><Trans i18nKey={'itemsList.sortAbcAsc'}></Trans></MenuItem>
                    <MenuItem selected={(sorting === 'titledesc')} onClick={() => this.handleSort('title', 'desc')}><Trans i18nKey={'itemsList.sortAbcDesc'}></Trans></MenuItem>
                    <MenuItem selected={(sorting === 'progressasc')} onClick={() => this.handleSort('progress', 'asc')}><Trans i18nKey={'itemsList.sortProgressAsc'}></Trans></MenuItem>
                    <MenuItem selected={(sorting === 'progressdesc')} onClick={() => this.handleSort('progress', 'desc')}><Trans i18nKey={'itemsList.sortProgressDesc'}></Trans></MenuItem>
                  </Menu>                
                </span>
              }
            </div>
          </Toolbar>
        {(filterQuery && data.length == 0) &&
          <div>
            <Divider />
            <Typography variant="h5" align="center" style={{padding: 24}}>
              {t('itemsList.noMatchesFound')}
            </Typography>
          </div>
        }
      </Sticky>
    </React.Fragment>
    );
  }

};

const toolbarStyles = theme => ({
  root: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    minHeight: 62,
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.9),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  actionBar: {
    position: 'fixed',
    display: 'flex',
    justifyContent: 'space-between',
    zIndex: 2000,
    top: 0,
    left: 0,
    right: 0,
    padding: '0 40px 0 calc(240px + 52px)',
    minHeight: 64,
  },
  actions: {
    color: theme.palette.text.secondary,
    position: 'relative',
    marginRight: 8,
  },
  spacer: {
    flex: '1 1',
  },
  title: {
    display: 'flex',
    flex: '1 auto',
  },
  sortMenu: {
    margin: '4px 0 0',
  },
  sticky: {
    marginTop: 63,
    zIndex: 10,
    backgroundColor: '#fff',
    borderBottom: 'solid 1px rgba(0, 0, 0, 0.12)',
    [theme.breakpoints.down('xs')]: {
      marginTop: 56,
    },
  },
  closeIcon: {
    cursor: 'pointer',
    padding: 12,
  },
  divider: {
    position: 'absolute',
    bottom: -1,
    left: 0,
    right: 0,
  },
  menuButton: {
    position: 'absolute',
    left: 12,
    display: 'none',
  },
  appBarLogo: {
    position: 'absolute',
    left: 0,
    width: 240,
    textAlign: 'center',
  },
  filterChip: {
    marginRight: 4,
  }
});

ItemsListToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
};

ItemsListToolbar = withStyles(toolbarStyles)(withTranslation()(ItemsListToolbar));








class ItemsList extends React.Component {
  listRef = null;
  itemRefs = [];
  boundings = null;
  lastItemHeight = 0;
  activeItem = null;
  routeBase = '';
  jumpToNextItem = false;
  delayTimeout = null;
  jumpToField = null;

  constructor(props) {
    super(props);
  
    window.restoreFieldsData = {};

    this.state = {
      data: props.data.slice(),
      query: '',
      order: localStorage.getItem('ly_order') ? localStorage.getItem('ly_order') : 'asc',
      orderBy: localStorage.getItem('ly_orderBy') ? localStorage.getItem('ly_orderBy') : 'title',
      filterGroup: props.allowGroupFiltering ? 'all' : null,
      selected: [],
      changed: [],
      expanded: [],
      page: 0,
      rowsPerPage: localStorage.getItem('ly_rowsPerPage') ? parseInt(localStorage.getItem('ly_rowsPerPage')) : 10,
      blockingPromptOpen: false,
      lastLocation: null,
      confirmedNavigation: false,
      isPending: false,
      isSubfieldsPending: false,
      isSaving: false,
      forceUpdate: false,
      forceOpen: false,
      anchorEl: null,
      triggerClearAllTranslations: false,
      triggerDiscardChanges: false,
      scrollToIndex: -1,
      disabled: false,
      disabledInfo: '',
      saveErrorsExpanded: false,
    };   
    
    /*
    props.data.forEach((item) => {
      if(!item.progress) {
        this.calcFieldsProgress(item.id, item.fields, true);
      }
    })
    */
  }

  componentDidMount() {
    //debugger;
    // Deeplinking
    let uriParts = this.props.location.pathname.split('/');
    let id = uriParts[3] ? uriParts[3] : uriParts[2];
    if(id) {
      this.calcSetPage(isNaN(id) ? id : parseInt(id));


      /*
      // When loadMoreButton is used, and there is a deeplinked open Item, and if the data array is empty,
      // add an dummy item
      if(this.props.data.length === 0 && this.props.onLoadMore) {
        alert('####')
      }
      */

      setTimeout(() => {
        this.handleOpenItem(null, isNaN(id) ? id : parseInt(id))
      }, 0);

      // Jump to field
      let hash = window.location.hash.substring(1);
      this.jumpToField = hash ? hash : null;
    }

    // Scroll calc
    this.initialScrollTop = document.getElementById('mainFrame').scrollTop;


    // Check if current section is used by task
    let outOfSyncString = window.localStorage.getItem('ly_bulk_translation:out_of_sync');
    if(outOfSyncString && outOfSyncString.indexOf(this.props.type) >= 0 && outOfSyncString.indexOf(`${this.props.languages.languages.find(x => x.id == this.props.editLanguage).code}_`) >= 0) {
      this.setState({
        disabled: true,
        disabledInfo: 'This section can\'t be edited until it\'s bulk process is finished.'
      });
    }

  }

  componentWillUnmount() {
    localStorage.removeItem('importFromOriginalNotAskAgain');
    this.closeSaveErrorSnackbar();
  }

  componentDidUpdate(prevProps) {
    const locationChanged = this.props.location !== prevProps.location;
    const pathnameChanged = this.props.location.pathname !== prevProps.location.pathname;
    const extraRouteSegmentChanged = this.props.extraRouteSegment !== prevProps.extraRouteSegment;
    const uriParts = this.props.location.pathname.split('/');
    const id = uriParts[3] ? uriParts[3] : uriParts[2];

    const wasSaving = prevProps.isSaving;
    const isSaving = this.props.isSaving;

    // Deeplinking
    if(pathnameChanged) {
      if(id) {
        this.calcSetPage(isNaN(id) ? id : parseInt(id));
        setTimeout(() => this.handleOpenItem(null, isNaN(id) ? id : parseInt(id)), 0);
      } else {
        this.setState({ expanded: [] });
      }
    }
    if(extraRouteSegmentChanged) {
      if(uriParts[1] === 'theme') {
        this.props.history.push('/theme')
        this.setState({ expanded: [] });

        if(window.mainFrameRef) {
          window.mainFrameRef.scroll({top: 0, behavior: 'smooth' });        
        }
      }
    }

    // Sort and Paginate after item was saved
    if(wasSaving === true && isSaving === false) {
      if(id) {
        this.calcSetPage(isNaN(id) ? id : parseInt(id));
      }
    }
  }

  componentWillReceiveProps(newProps) {
    const wasPending = this.props.isPending;
    const isPending = newProps.isPending;

    const wasRemoving = this.props.isRemoving;
    const isRemoving = newProps.isRemoving;

    const wasSubfieldsPending = this.props.isSubfieldsPending;
    const isSubfieldsPending = newProps.isSubfieldsPending;

    const wasSubfieldsRemoving = this.props.isSubfieldsRemoving;
    const isSubfieldsRemoving = newProps.isSubfieldsRemoving;

    const wasSaving = this.props.isSaving;
    const isSaving = newProps.isSaving;

    const wasDisabled = this.props.disabled;
    const isDisabled = newProps.disabled;

    this.setState({isPending: isPending, isSubfieldsPending: isSubfieldsPending, isSaving: isSaving});

    if((wasPending === true && isPending === false)
    || (wasSubfieldsPending === true && isSubfieldsPending === false)) {
      const newData = newProps.data.slice();
      const oldData = this.state.data;

      newData.forEach(newItem => {
        const oldItem = oldData.find(x => x.id == newItem.id);

        newItem.fields.forEach(newField => {
          if(newField.type === 'subfields') {

            newField.fields.forEach(newSubfield => {
              const oldSubfield = oldItem.fields.find(x => x.id == newField.id).fields.find(x => x.id == newSubfield.id);

              if(oldSubfield) {
                // If the data has been saved just use the new data
                if(!(wasSaving === true && isSaving === false)) {
                  newSubfield.target = oldSubfield.target;
                }
              }
            });
          } else {
            if(oldItem && oldItem.synchronized === true) {
              // If the data has been saved just use the new data
              if(!(wasSaving === true && isSaving === false)) {
                const oldField = oldItem.fields.find(x => x.id == newField.id);
                if(oldField) {
                  newField.target = oldField.target;
                }
              }
            }
          }
        })

        //if(newItem.synchronized === true) {
        //  newItem.progress = this.calcFieldsProgress(newItem.id, newItem.fields, false);
        //}
      });

      this.setState({data:newData});
    }



    // Update the discard state if an item has been removed
    if(wasSubfieldsRemoving === true && isSubfieldsRemoving === false) {
      this.state.data.forEach(item => {
        item.fields.forEach(field => {
          if(field.type === 'subfields') {
            field.fields.forEach(subfield => {
              if(!newProps.data.find(x => x.id == item.id).fields.find(x => x.id == field.id).fields.find(x => x.id == subfield.id)) {
                delete window.restoreFieldsData[item.id + '-' + field.id + '-' + subfield.id];
              }
            })
          }
        })
      })
    }
    if(wasRemoving === true && isRemoving === false) {
      this.state.data.forEach(item => {
        item.fields.forEach(field => {
          if(!newProps.data.find(x => x.id == item.id).fields.find(x => x.id == field.id)) {
            delete window.restoreFieldsData[item.id + '-' + field.id];
          }
        })
      })
    }

    if((wasSubfieldsRemoving === true && isSubfieldsRemoving === false) || (wasRemoving === true && isRemoving === false)) {
      const newSelected = [];
      this.state.selected.forEach(item => {
        if((Object.keys(window.restoreFieldsData).find(x => x.startsWith(item + '-')))) {
          newSelected.push(item);
        }
      })
      this.setState({selected:newSelected});
    }

    // Save & Next
    if(wasSaving === true && isSaving === false) {

      if(!newProps.error) {
        this.setState({selected: []});
      }
      if(this.jumpToNextItem) {
        const lastIndex = this.state.data.findIndex(x => x.id == this.jumpToNextItem);
        if(lastIndex+1 < this.state.data.length) {
          const nextId = this.state.data[lastIndex+1].id;
          this.jumpToNextItem = false;
          this.calcSetPage(nextId);
          setTimeout(() => this.handleOpenItem(null, nextId), 0);
        }
      }
    }

    // Error handling
    // wenn error.level 1 => collapse 
    if(this.props.error && this.props.error.level === 1) {
      if(newProps.error === false && !newProps.errorLockCollapse && this.state.expanded.length) {
        let path = this.props.location.pathname;
        this.props.history.push(path.substring(0, path.lastIndexOf('/')));
      }
    }




    // Nach dem Speichern, aus window.restoreFieldsData alle Einträge entfernen, deren Felder keine Errors haben
    if(wasSaving === true && isSaving === false) {
      let saveErrorsKeyStringList = [];
      
      if(newProps.saveErrors && newProps.saveErrors.length > 0 && Array.isArray(newProps.saveErrors)) {
        newProps.saveErrors.map(item => {
          return parseItem2FieldIdsPath(item);
        })
      }

      saveErrorsKeyStringList = saveErrorsKeyStringList.flat();
      //debugger;
      //console.log(saveErrorsKeyStringList)
      Object.keys(window.restoreFieldsData).forEach((key, i) => {
        if (newProps.saveErrors && newProps.saveErrors.length > 0) {
          if (saveErrorsKeyStringList.indexOf(key) === -1) {
            delete window.restoreFieldsData[key];
          }
        } else {
          delete window.restoreFieldsData[key];
        }
      })
      //console.log(window.restoreFieldsData);
    }


    // Save Infos
    if(newProps.saveInfos && newProps.saveInfos.length > 0 && _.isEqual(newProps.saveInfos, this.props.saveInfos) === false) {
      const newContent = <ErrorBoundary msg={''}>
        <SaveInfosList 
          infos={newProps.saveInfos} 
          scope={this.props.type} 
          rawData={this.props.data}
          history={this.props.history}
        />
      </ErrorBoundary>
      this.props.enqueueSnackbar('Errors occured', {
        variant: 'error',
        persist: true,
        content: newContent
      });
    }


    // Save Errors
    if(newProps.saveErrors && newProps.saveErrors.length > 0 && _.isEqual(newProps.saveErrors, this.props.saveErrors) === false) {
      const newContent = <ErrorBoundary msg={''}>
        <SaveErrorsList 
          errors={newProps.saveErrors} 
          scope={this.props.type} 
          rawData={this.props.data}
          history={this.props.history}
        />
      </ErrorBoundary>
      this.props.enqueueSnackbar('Errors occured', {
        variant: 'error',
        persist: true,
        content: newContent
      });
      // window.restoreFieldsData für alle saveErrors setzen
      let newSelected = [];
      newProps.saveErrors.forEach(item => {
        if(item.object) {
          newSelected.push(parseItemId(item.object));
          item.errors.forEach(error => {

            // Only write restoreValue once 
            if(!window.restoreFieldsData[parseItemId(item.object) + '-' + error.object_key] && window.restoreFieldsData[parseItemId(item.object) + '-' + error.object_key] !== '') {
              let restoreValue = '';
              try {
                if(error.object_subfieldsId) {
                  restoreValue = newProps.data.find(x => x.id === parseItemId(item.object)).fields.find(x => x.id === error.object_subfieldsId).fields.find(x => x.id === error.object_key).restore;
                } else {
                  restoreValue = newProps.data.find(x => x.id === parseItemId(item.object)).fields.find(x => x.id === error.object_key).restore;
                }
              } catch(err) {
                console.log(err);
              }

              const subfieldsId = error.object_subfieldsId ? '-' + error.object_subfieldsId : '';
              window.restoreFieldsData[parseItemId(item.object) + subfieldsId + '-' + error.object_key] = restoreValue || '';
            }
          })          
        }
      })
      this.setState({selected:newSelected});
    }


    const currentLanguage = this.props.shop.shop.edit_language;
    const newLanguage = newProps.shop.shop.edit_language;
    if(currentLanguage != newLanguage) {
      document.getElementById('mainFrame').scroll({top: 0, behavior: 'smooth'});
    }


    /*
    console.log('#####', newProps.languages.languages, newProps.editLanguage, newProps.languages.languages.find(x => x.id == newProps.editLanguage).code)
    if(outOfSyncString) {
      let outOfSyncLanguages = outOfSyncString.split(';');
      let newOutOfSyncSections = {};
      outOfSyncLanguages.forEach(item => {
        if(item !== '') {
          let language = item.split('_')[0]
          let objects = item.split('_')[1].split(',');
          newOutOfSyncSections[language] = objects;
        }
      });

      this.setState({disabled: true});
    }
    */

    // Disabled
    //if(wasDisabled !== isDisabled) {
    //  this.setState({disabled: isDisabled});
    //}


  }

  setListRef = (ref) => {
    this.listRef = ref;
    if(ReactDOM.findDOMNode(this.listRef)) {
      this.boundings = ReactDOM.findDOMNode(this.listRef).getBoundingClientRect();
    }
  }

  calcSetPage = (id) => {
    const { data, query, order, orderBy, rowsPerPage, filterGroup } = this.state;
    const { hideSorting } = this.props;
    let sortedArr = hideSorting ? filterItems(data, query, filterGroup) : stableSort(filterItems(data, query, filterGroup), getSorting(order, orderBy))
    let itemIndex = sortedArr.findIndex(x => x.id == id);
    let pageNum = Math.floor(itemIndex / this.state.rowsPerPage);
    if(pageNum === -1) pageNum = 0;
    this.setState({ page: pageNum });
  }

  scrollToItem = (id, jump) => {
    const iel = document.getElementById(`list-item-${id}`);
    if(iel && this.boundings) {
      const jumpOffset = jump ? 1 : 0;
      const paginationHeight = this.props.pagination ? 56 : 0;
      const offset = this.initialScrollTop + this.boundings.top + paginationHeight + (iel.getAttribute('data-index') - jumpOffset) * 63;
      document.getElementById('mainFrame').scroll({top: offset + 1, behavior: !jump ? 'smooth' : 'auto' });
    }    
  }

  scrollToField = (itemId, fieldId) => {
    this.setState({
      scrollToIndex: this._calcFieldIndex(itemId, fieldId)
    })
  }
  _calcFieldIndex = (itemId, fieldId) => {
    const itemData = this.state.data.find(x => x.id === itemId);
    let index = 0;
    if(itemData.fields) {
      for(let i=0; i < itemData.fields.length; i++) {
        let field = itemData.fields[i];
        if(field.id == fieldId) {
          index = i;
          break;
        }
      }
    }
    return index;
  }


  onItemExpand = (id) => {
    this.scrollToItem(id);
    if(this.props.onExpand) {
      this.props.onExpand(id);
    }
  }

  onItemExpanded = (id) => {
    if(this.jumpToField) {
      const params = this.jumpToField.split(':');
      // Goto a field inside a list-item...
      if(params.length === 1)
        this.scrollToField(id, params[0]);
      // ...or goto a field inside a "subfields" field...
      if(params.length > 1) {
        //alert(`Open field: ${params[1]} in subfield: ${params[0]}`)
        this._openSubfields(params[0], params[1]);
      }
    } 
    // ...or goto a list-item
    else {
      this.scrollToItem(id);
    }
    if(this.props.onExpanded) {
      this.props.onExpanded(id);
    }
  }

  onItemCollapse = (id) => {
    const uriParts = this.props.location.pathname.split('/');
    if(!uriParts[2]) {
      this.scrollToItem(id, true);
    }
    if(this.props.onCollapse) {
      this.props.onCollapse(id);
    }
  }

  onItemCollapsed = (id) => {
    if(this.props.onCollapsed) {
      this.props.onCollapsed(id);
    }
  }

  onExpandCollapsableFields = (type, id) => {
    if(this.props.onExpandCollapsableFields) {
      this.props.onExpandCollapsableFields(type, id);
    }
  }


  onFieldSelected = (itemId, fieldId) => {
    //this.scrollToField(itemId, fieldId);
  }

  onSubfieldSelected = (itemId, fieldId, subfieldId) => {
    
  }

  onSubfieldsExpand = (subfieldsElem) => {
    var rect = subfieldsElem.getBoundingClientRect();
    const paginationHeight = this.props.pagination ? 56 : 0;
    var offset = document.getElementById('mainFrame').scrollTop + rect.top - 128 - 44;
    window.mainFrameRef.scroll({top: offset, behavior: 'smooth' });     
  }

  onSubfieldsExpanded = (subfieldsElem) => {
    //console.log(subfieldsElem)
  }

  onSubfieldsCollapsed = (subfieldsElem) => {
    //console.log(subfieldsElem)
  }

  _openSubfields = (subfieldId, fieldId) => {
    
    // force update 
    this.setState({
      forceUpdate: true,
      forceOpen: subfieldId
    }, () => {
      this.setState({
        forceUpdate: false 
      });      
    });
  }




  handleClick = (event, id) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    this.setState({ selected: newSelected });
  };

  handleOpenItem = (event, id) => {
    const { expanded } = this.state;
    const expandedIndex = expanded.indexOf(id);
    let newExpanded = [];

    if(event && event.target.type === 'checkbox') {
      let isExpanded = expanded.includes(id);
      let isSelected = !this.state.selected.includes(id);
      if(isExpanded || !isSelected) {
        return;
      }
    }

    if (expanded[0] === id) {
      newExpanded = [];
    } else {
      newExpanded = [id];
    }

    //console.log('handleOpenItem:', new Date().getTime(), id);

    this.setState({ expanded: newExpanded });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
    document.getElementById('mainFrame').scroll({top: this.boundings.top - 63, behavior: 'smooth' })
  };

  handleChangeRowsPerPage = event => {
    localStorage.setItem('ly_rowsPerPage', event.target.value)
    this.setState({ 
      page: 0,
      rowsPerPage: event.target.value 
    });
  };

  handleChangeText = (event, data, id) => {
    let itemData = null;
    let newData = this.state.data.slice();
    if(data.parentField) {
      newData.find(x => x.id === id).fields.find(x => x.id === data.parentField).fields.filter(x => x.id === data.id).forEach(x => {
        x.target = data.target;
        x.error = data.error;
      });
    } else {
      newData.find(x => x.id === id).fields.find(x => x.id === data.id).target = data.target;
      newData.find(x => x.id === id).fields.find(x => x.id === data.id).error = data.error;
    }

    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if(selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
      this.setState({ selected: newSelected });
    }
    
    const parentId = data.parentField ? '-'+data.parentField : '';
    if(!window.restoreFieldsData[`${id}${parentId}-${data.id}`] && window.restoreFieldsData[`${id}${parentId}-${data.id}`] !== '') {
      window.restoreFieldsData[`${id}${parentId}-${data.id}`] = data.initial || '';
    } else if(window.restoreFieldsData[`${id}${parentId}-${data.id}`] === data.target) {
      delete window.restoreFieldsData[`${id}${parentId}-${data.id}`];
      if(Object.keys(window.restoreFieldsData).filter(x => x.indexOf(`${id}-`) !== -1).length == 0) {
        newSelected = selected.filter(item => item !== id);
        this.setState({ selected: newSelected });        
      }
    }

    this.setState({ data: newData });
  };

  handleDiscardChanges = (event) => {
    let newData = this.state.data.slice();
    let itemIds = {};
    //debugger;
    Object.keys(window.restoreFieldsData).forEach((key, i) => {
      const fieldIds = key.split('-');
      itemIds[fieldIds[0]] = true;
      let item;
      if(fieldIds.length === 2) {
        newData.find(x => x.id == fieldIds[0]).fields.find(x => x.id == fieldIds[1]).target = window.restoreFieldsData[key];
        item = newData.find(x => x.id == fieldIds[0]).fields.find(x => x.id == fieldIds[1]);
      } else {
        item = newData.find(x => x.id == fieldIds[0]).fields.find(x => x.id == fieldIds[1]).fields.find(x => x.id == fieldIds[2]);
      }
      item.target = window.restoreFieldsData[key];
      if(item.errors) {
        delete item.errors;
      }
    });
    // force update 
    this.setState({
      //forceUpdate: true,
      triggerDiscardChanges: !this.state.triggerDiscardChanges,
      data: newData,
      selected: [],
    }, () => {
      this.setState({
        //forceUpdate: false 
      });      
    });

    setTimeout(() => {
      window.restoreFieldsData = {}
    }, 100);
  }

  calcFieldsProgress = (id, fields, setState) => {
    let progress = 0;
    let fieldsNum = fields.reduce((n, x) => n + (x.type != 'subfields'), 0);
    let emptyNum = fields.reduce((n, x) => n + (x.target === '' && x.type != 'subfields'), 0);
    fields.map(field => {
      if(field.type === 'subfields' && field.id != 'seofields' && field.id != 'metafields' && field.id != 'variant_metafields' && field.id != 'images') {
        fieldsNum += field.fields.reduce((n, x) => n + (x.type != 'subfields'), 0);
        emptyNum += field.fields.reduce((n, x) => n + (x.target === '' && x.type != 'subfields'), 0);
      }
    });
    if(fieldsNum > 0) {
      progress = (1 - emptyNum / fieldsNum) * 100;
    } else {
      progress = 'empty';
    }
    if(setState) {
      let newData = this.state.data.slice();
      newData.find(x => x.id == id).progress = progress;
      this.setState({data: newData});      
    }
    return progress;
  }

  handleClearAllTranslations = () => {
    this.setState({
      query: '',
      triggerClearAllTranslations: !this.state.triggerClearAllTranslations
    })
  }

  handleSave = (nextId) => {
    // Save & Next
    if(nextId) {
      this.jumpToNextItem = nextId;
    }

//debugger;    

    const selectedItems = this.state.data.filter(item => this.state.selected.includes(item.id));

    // Remove the synchronized flag for all items that are going to be saved
    let newData = this.state.data.slice();
    selectedItems.forEach((item, key) => {
      newData.find(x => x.id == item.id).synchronized = false
    })
    this.setState({
      data: newData
    });

    /*
    // TODO: Remove only the items from the this.restoreFieldData object, that have no matching entry in the this.props.saveErrors
    Object.keys(window.restoreFieldsData).forEach((key, i) => {
      const ids = key.split('-');
      const itemId = ids[0];
      const fieldId = ids[1];

      let isInSaveErrors = null;
      try {
        isInSaveErrors = this.props.saveErrors.find(x => x.object.split('/')[x.object.split('/').length-1] == itemId).errors.find(y => y.object_key == fieldId).length === 1; 
      }
      catch {
        isInSaveErrors = false;
      }
      finally {
        if(!isInSaveErrors) {
          console.log(window.restoreFieldsData[`${itemId}-${fieldId}`])
          //delete window.restoreFieldsData[`${itemId}-${fieldId}`];
        }
      }
    });
    */
    let selectedFields = {...window.restoreFieldsData};
    //console.log(window.restoreFieldsData)
    //window.restoreFieldsData = {};

    this.closeSaveErrorSnackbar();

    this.props.onSave(selectedItems, selectedFields);
  }

  handleQueryChange = (event) => {
    this.setState({ 
      query: event.target.value,
      page: 0,
    })
  }

  handleFilterGroup = (group) => {
    //alert(group);
    this.setState({ 
      filterGroup: group,
      page: 0,
    });
  }

  handleSort = (field, order) => {
    localStorage.setItem('ly_orderBy', field);
    localStorage.setItem('ly_order', order);
    this.setState({ 
      orderBy: field,
      order: order,
      page: 0,
    }, () => {
      const { data, query, order, orderBy, rowsPerPage, filterGroup } = this.state;
      if(this.state.expanded.length > 0) {
        let sortedArr = stableSort(filterItems(data, query, filterGroup), getSorting(order, orderBy))
        let itemIndex = sortedArr.findIndex(x => x.id == this.state.expanded[0]);
        let pageNum = Math.floor(itemIndex / rowsPerPage);
        if(pageNum === -1) pageNum = 0;
        this.setState({ page: pageNum }, () => {
          this.scrollToItem(this.state.expanded[0]);
        });
      }    
    });
  }

  handleOptionsMenuClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleOptionsMenuClose = () => {
    this.setState({ anchorEl: null });
  };

  closeSaveErrorSnackbar = () => {
    // Close error snackbar if needed
    this.props.closeSnackbar();
  }

  shouldBlockNavigation = (nextLocation) => {
    const uriParts1 = this.props.location.pathname.split('/');
    const uriParts2 = nextLocation.pathname.split('/');
    return (uriParts1[1] === uriParts2[1]) ? false : true;
  }

  handleBlockedNavigation = (nextLocation) => {
    const {confirmedNavigation} = this.state
    const {shouldBlockNavigation} = this.props
    if (!confirmedNavigation && this.shouldBlockNavigation(nextLocation)){
      this.setState({
        blockingPromptOpen: true,
        lastLocation: nextLocation,
      });
      return false
    }
    return true
  }

  handleConfirmNavigationClick = () => this.handleCloseBlockingPromt(() => {
    const {history} = this.props
    const {lastLocation} = this.state
    if (lastLocation) {
       this.setState({
          confirmedNavigation: true
       }, () => {
          // Navigate to the previous blocked location with your navigate function     
          history.push(lastLocation.pathname)
       })
    }
  })

  handleCloseBlockingPromt = (callback) => {
    this.setState({blockingPromptOpen: false}, callback);
  }

  isSelected = id => this.state.selected.indexOf(id) !== -1;
  isExpanded = id => {
    return this.state.expanded.findIndex(function(item){ return item == id}) != -1;
  }
  getItemIdfromIndex = (arr, index) => {
    return arr[index].id
  }


  renderBlockingPrompt = () => {
    const { t } = this.props;
    return (
      <React.Fragment>
        <Prompt
          when={this.state.selected.length > 0}
          message={this.handleBlockedNavigation}
        />
        <Dialog
          open={this.state.blockingPromptOpen ? true : false}
          onClose={() => this.handleCloseBlockingPromt(null)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          BackdropProps={{
            style: {
              backgroundColor: 'rgba(255, 255, 255, .5)'
            }
           }
          }
        >
          <DialogTitle id="alert-dialog-title">{t('itemsList.blockingPromptTitle')}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {t('itemsList.blockingPromptDescription')}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.handleCloseBlockingPromt(null)} color="default" variant="outlined">
              {t('system.cancel')}
            </Button>
            <Button className={this.props.classes.alertBtn} onClick={this.handleConfirmNavigationClick} color="secondary" variant="contained" autoFocus>
              {t('itemsList.blockingPromptLeavePage')}
            </Button>
          </DialogActions>
        </Dialog>        
      </React.Fragment>
    );
  }

  renderPageSelect = ({ from, to, count }) => {
    const { t, classes } = this.props;
    const { rowsPerPage } = this.state;
    let pages = Math.ceil(count / rowsPerPage);
    let pageSelectItems = [];
    for(var a=1; a<=pages; a++) {
      pageSelectItems.push(<MenuItem id={`page-${a}`} key={`page-${a}`} value={a-1}>{a}</MenuItem>);
    }
    return (
      <FormControl component={'span'} id="ItemsList-pagination-pageselect">
        <span id="page-select-label">{t('itemsList.page')}: </span>
        <Select
          labelId="page-select-label"
          size="small"
          onChange={(e) => this.handleChangePage(e, e.target.value)}
          value={this.state.page}
          MenuProps={{
            PaperProps: { sx: { maxHeight: 360 } },
            classes: { paper: classes.menuPaper } 
          }}
          disableUnderline
        >
          {pageSelectItems}
        </Select>     
        <span style={{position: 'absolute', left: 24, margin: '14px 0'}}>
          {`${t('itemsList.showingItem')} ${from}–${to} ${t('itemsList.of')} ${count !== -1 ? count : `${t('itemsList.moreThan')} ${to}`}`}
        </span>
      </FormControl>
    );
  }

  render() {
    const { t, isPending, isSticky, hideFilterbar, hideSorting, allowGroupFiltering, extraRouteSegment, virtualized, paginate, toolbarTitle, allowAutoTranslation, allowFieldFiltering, classes, itemsPrependList, loadMoreButton, useLazyloading} = this.props;
    const { data, query, order, orderBy, selected, rowsPerPage, page, anchorEl, filterGroup, scrollToIndex } = this.state;

    const fromLanguageCode = this.props.languages.languages.find(x => x.base == true).code.toUpperCase();
    const toLanguageCode = this.props.languages.languages.find(x => x.id == this.props.shop.shop.edit_language).code.toUpperCase();

    let filteredData = filterItems(data, query, filterGroup);
    let noFilterResults = false;
    let processedData = [];
    if(useLazyloading) {
      processedData = data;
    } 
    else {
      processedData = paginateItems(
        hideSorting ? filteredData : stableSort(filteredData, getSorting(order, orderBy)),
        paginate, 
        page, 
        rowsPerPage
      )
      if(filteredData.length === 0) {
        filteredData = data;
        noFilterResults = true;
      }
    }


    return (
      <React.Fragment>
        {this.renderBlockingPrompt()}

        {this.state.disabled &&
          <div style={{position: 'absolute', zIndex: 100, width: '100%', height: '100%', left: 0}}>
            {this.state.disabledInfo &&
              <Typography className={classNames('disabled-info', classes.disabledInfo)}>{this.state.disabledInfo}</Typography>
            }
          </div>
        }
        
        {data.length > 0 ?

          <Paper id="ItemsList" 
            ref={this.setListRef}
            className={classNames(classes.root, this.state.disabled ? 'disabled' : null)} 
            elevation={1}
          >
            <ItemsListToolbar 
              data={filteredData ? filteredData : null}
              numSelected={selected.length} 
              deletable={this.props.deletable} 
              onDelete={() => {this.props.onDelete(selected)}} 
              onSave={() => this.handleSave(null)} 
              onDiscard={() => this.handleDiscardChanges()}
              onQueryChange={this.handleQueryChange} 
              onSort={this.handleSort}
              hideFilterbar={hideFilterbar}
              hideSorting={hideSorting}
              allowGroupFiltering={allowGroupFiltering}
              onFilterGroup={this.handleFilterGroup}
              filterModules={this.props.filterModules}
              isSticky={isSticky}
              isPending={isPending}
              toolbarTitle={toolbarTitle}
            />
            <Divider />
            <div className={classes.tableWrapper}>
              {noFilterResults &&
                <Paper className={classes.infoBox} elevation={0}>
                  <Typography variant="subtitle1" className={classes.infoText}><WarningOutlinedIcon style={{ fontSize: 24, marginTop: -2 }} /> {t('itemsList.noFilterResults')}</Typography>
                </Paper>
              }
              {this.props.pagination &&
                <React.Fragment>
                  <TablePagination
                    component="div"
                    count={filteredData.length}
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    backIconButtonProps={{ 'aria-label': t('itemsList.prevPage') }}
                    nextIconButtonProps={{ 'aria-label': t('itemsList.nextPage') }}
                    onPageChange={this.handleChangePage}
                    onRowsPerPageChange={this.handleChangeRowsPerPage}
                    labelRowsPerPage={t('itemsList.rowsPerPage')}
                    //labelDisplayedRows={({ from, to, count }) => { return `${from}–${to} ${t('itemsList.of')} ${count !== -1 ? count : `${t('itemsList.moreThan')} ${to}`}`; }}
                    labelDisplayedRows={this.renderPageSelect}
                    //ActionsComponent={this.renderPaginationActionsComponent}
                  />
                  <Divider />                  
                </React.Fragment>
              }
              <List component="div" 
                dense={false}
                style={{padding: 0}}>
                {
                  processedData.map((n, i, a) => {
                    this.itemRefs[n.id] = React.createRef();
                    const isSelected = this.isSelected(n.id);
                    const isExpanded = this.isExpanded(n.id);

                    let uriParts = this.props.location.pathname.split('/');
                    let uri = extraRouteSegment ? `${extraRouteSegment}/${n.id}` : n.id;
                    let uriPrev = extraRouteSegment ? `${extraRouteSegment}/${i >= 1 ? this.getItemIdfromIndex(a, i-1) : false}` : `${i >= 1 ? this.getItemIdfromIndex(a, i-1) : false}`;
                    let uriNext = extraRouteSegment ? `${extraRouteSegment}/${i < a.length-1 ? this.getItemIdfromIndex(a, i+1) : false}` : `${i < a.length-1 ? this.getItemIdfromIndex(a, i+1) : false}`;

                    return (
                      <div className={classNames("ItemsList_list-item", isExpanded && 'expanded')} 
                        key={`list-item-${n.id}`} 
                        id={`list-item-${n.id}`} 
                        ref={ref => this.itemRefs[n.id] = ref} 
                        data-index={i}
                      >
                        <Sticky 
                          scrollElement="#mainFrame"  
                          boundaryElement={`#list-item-${n.id}`}
                          topOffset={-64}
                          disabled={(!isExpanded || query !== '')}
                          hideOnBoundaryHit={true}
                          positionRecheckInterval={100}
                          stickyClassName={classNames('sticky', classes.stickyItem)}
                        >
                          {/*
                          <Checkbox
                            checked={this.isSelected(n.id)}
                            onChange={event => this.handleClick(event, n.id)}
                            style={{padding: '19px 24px', position: 'absolute', zIndex: 1}}
                          /> 
                          <Tooltip title="Save" className="inline-save-btn">
                            <Button aria-label="Save" variant="outlined" size="small" onClick={() => this.handleSave(null)}>
                              Save <SaveOutlinedIcon style={{marginLeft: 5}} />
                            </Button>
                          </Tooltip> 
                          */} 
                          <React.Fragment>
                            {isExpanded && 
                              <span className={'inline-actions'}>
                                <Link className={classes.linkReset} to={uriPrev.indexOf('false')>=0 ? `/${uriParts[1]}/${uri}` : `/${uriParts[1]}/${uriPrev}`}> 
                                  <IconButton disabled={uriPrev.indexOf('false')>=0} aria-label="Prev" variant="outlined" className="inline-save-btn" style={{marginLeft: 5, minWidth: 'unset'}}>
                                    <ArrowBackOutlined className={classes.iconSmall} />
                                  </IconButton>
                                </Link>
                                <Link className={classes.linkReset} to={uriNext.indexOf('false')>=0 ? `/${uriParts[1]}/${uri}` : `/${uriParts[1]}/${uriNext}`}> 
                                  <IconButton disabled={uriNext.indexOf('false')>=0} aria-label="Next" variant="outlined" className="inline-save-btn" style={{marginLeft: 5, minWidth: 'unset'}}>
                                    <ArrowForwardOutlined className={classes.iconSmall} />
                                  </IconButton>
                                </Link>
                                <span style={{position: 'relative'}}>
                                  <IconButton style={{marginLeft: 5}}
                                    aria-owns={anchorEl ? 'options-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-label="Options list"
                                    onClick={this.handleOptionsMenuClick}
                                  >
                                    <MoreVertIcon />
                                  </IconButton>
                                  <Menu
                                    id="options-menu"
                                    anchorEl={anchorEl}
                                    open={Boolean(anchorEl)}
                                    onClose={this.handleOptionsMenuClose}
                                    disableAutoFocusItem
                                    style={{ top: 56, right: 0 }}
                                    anchorOrigin={{
                                      horizontal: 'right',
                                      vertical: 'top',
                                    }}
                                    transformOrigin={{
                                      vertical: 'top',
                                      horizontal: 'right',
                                    }}
                                  >
                                    {this.props.onAddItem ? (<MenuItem onClick={() => {this.handleOptionsMenuClose(); this.props.onAddItem(n.id);}}><AddIcon style={{marginRight:'10px'}}/>{this.props.addItemLabel ? this.props.addItemLabel : t('system.addItem')}</MenuItem>) : null}
                                    <MenuItem onClick={() => {this.handleOptionsMenuClose(); this.handleClearAllTranslations();}}><ClearAllIcon style={{marginRight:'10px'}}/><span style={{color:'red'}}>{t('system.clearAllTranslations')}</span></MenuItem>
                                    {this.props.deletable && <MenuItem onClick={() => {this.handleOptionsMenuClose(); this.props.onDelete([n.id])}}><DeleteIcon style={{marginRight:'10px'}}/>{t('system.deleteSection')}</MenuItem>}
                                    {this.props.onResaveAll ? (<MenuItem onClick={() => {this.handleOptionsMenuClose(); this.props.onResaveAll([n.id]);}}><ResaveIcon style={{marginRight:'10px'}}/>{t('system.resaveAll')}</MenuItem>) : null}
                                  </Menu>                
                                </span>
                                {/*(isExpanded && selected.length > 0) && 
                                  <span>
                                    <Button aria-label="Save & Next" variant="outlined" size="small" onClick={() => this.handleSave(n.id)} className="inline-save-btn" style={{marginLeft: 5}}>
                                      Save & Next
                                    </Button>
                                  </span>
                                */}       
                              </span> 
                            }

                            <Link className={classes.linkReset} to={!isExpanded ? `/${uriParts[1]}/${uri}` : `/${uriParts[1]}`}>
                              <ListItem
                                className={classes.listItemHeader}
                                disabled={this.state.isPending}
                                button
                              >    
                                <ListItemText inset 
                                  primary={
                                    <React.Fragment>
                                      {n.title}
                                      {typeof n.shopify_id !== "undefined" && <Chip label={"#" + n.shopify_id.split('/')[n.shopify_id.split('/').length-1]} title={"Shopify Id"} variant="outlined" size="small" classes={{root: classes.shopifyId}} />}
                                      {this.isSelected(n.id) && <Chip label={t('itemsList.unsaved')} size="small" style={{fontWeight: 'normal !important', marginLeft: 12}} />}
                                    </React.Fragment>
                                  } 
                                  primaryTypographyProps={{
                                    noWrap: true,
                                    style: {
                                      opacity: (isExpanded || !this.state.expanded) ? 1 : .65,
                                      paddingRight: isExpanded ? 140 : 10, 
                                      position: 'relative',
                                      bottom: n.subtitle ? '5px' : '0px',
                                      color: n.hasError ? 'red' : 'inherit',
                                      fontWeight: n.hasError ? 'bold' : 'inherit',
                                    },
                                  }}
                                  secondary={<span>
                                    <ProgressIndicator progress={n.progress} outOfSync={n.out_of_sync === true}/>
                                    {n.hasError && <ErrorOutlineOutlinedIcon size={12} style={{position: 'absolute', left: 21, top: 17, width: '1.25em', height: '1.25em', color: 'red', backgroundColor: 'white', borderRadius: '1em'}} />}
                                  </span>} 
                                  secondaryTypographyProps={{
                                    component: 'span'
                                  }}
                                />
                                {n.subtitle ? (
                                    <span style={{position: 'absolute', left: '73px', top: '33px', fontSize: '11px', color: 'rgba(0, 0, 0, 0.6)' }}>{n.subtitle}</span>
                                  ) : (
                                    null
                                  )
                                }
                                {/*<ExpandLess className={classes.expandIcon} />*/}
                                {isExpanded ? null : <ExpandMore className={classes.expandIcon} />}      
                              </ListItem>  
                            </Link>         
                          </React.Fragment>    
                        </Sticky>

                        <Collapse 
                          in={isExpanded} 
                          timeout={500}
                          onEnter={() => this.onItemExpand(n.id)}
                          onEntered={() => this.onItemExpanded(n.id)}
                          onExit={() => this.onItemCollapse(n.id)}
                          onExited={() => this.onItemCollapsed(n.id)}
                          classes={{
                            wrapperInner: classes.collapseWrapperInner
                          }}
                          unmountOnExit
                        >
                          {(itemsPrependList && itemsPrependList[n.id]) &&
                            <div>{itemsPrependList[n.id]}</div>
                          }
                          <TranslationFields 
                            key={n.id}
                            itemId={n.id}
                            data={n.fields} 
                            virtualized={virtualized}
                            isPending={(this.state.isPending && !this.state.isSaving && !this.state.isSubfieldsPending) || (this.props.error && this.props.error.level === 1)} 
                            isSubfieldsPending={this.props.isSubfieldsPending}
                            allowAutoTranslation={allowAutoTranslation}
                            allowFieldFiltering={(allowFieldFiltering && !noFilterResults)}
                            filterQuery={(allowFieldFiltering && !noFilterResults) ? query : ''}
                            filterGroup={filterGroup}
                            from={fromLanguageCode}
                            to={toLanguageCode}
                            forceUpdate={this.state.forceUpdate}
                            forceOpen={this.state.forceOpen}
                            triggerClearAllTranslations={this.state.triggerClearAllTranslations}
                            triggerDiscardChanges={this.state.triggerDiscardChanges}
                            emptyMessage={n.emptyMessage}
                            scrollToIndex={scrollToIndex}

                            onSave={this.handleSave} 
                            onDeleteItem={this.props.onDeleteItem}
                            onAddItem={this.props.onAddItem}
                            onChange={(event, data) => this.handleChangeText(event, data, n.id)}
                            onExpandCollapsableFields={(type) => this.onExpandCollapsableFields(type, n.id)} 
                            onFieldSelected={this.onFieldSelected}
                            onSubfieldSelected={this.onSubfieldSelected}
                            onSubfieldsExpand={this.onSubfieldsExpand}
                            onSubfieldsExpanded={this.onSubfieldsExpanded}
                            onSubfieldsCollapsed={this.onSubfieldsCollapsed}

                            subfieldsPaginateFunc={this.props.subfieldsPaginateFunc}
                          />
                        </Collapse>
                        <Divider />
                      </div>
                    );
                  })
                }
              </List>
            </div>
            { this.props.pagination === false ? (
              null
              ) : (
              <TablePagination
                component="div"
                count={filteredData.length}
                rowsPerPageOptions={[10, 25, 50, 100]}
                rowsPerPage={rowsPerPage}
                page={page}
                backIconButtonProps={{'aria-label': t('itemsList.prevPage')}}
                nextIconButtonProps={{'aria-label': t('itemsList.nextPage')}}
                onPageChange={this.handleChangePage}
                onRowsPerPageChange={this.handleChangeRowsPerPage}
                labelRowsPerPage={t('itemsList.rowsPerPage')}
                //labelDisplayedRows={({ from, to, count }) => { return `${from}–${to} ${t('itemsList.of')} ${count !== -1 ? count : `${t('itemsList.moreThan')} ${to}`}`; }}
                labelDisplayedRows={this.renderPageSelect}
                //ActionsComponent={this.renderPaginationActionsComponent}
              />
            )}
          </Paper>
        :
          this.props.emptyMessage
        } 
        {this.props.loadMoreButton && this.props.onLoadMore &&
          <Grid container justifyContent="center" direction="row" style={{padding: '24px 0'}}>
            <Button size="large" variant="contained" color="primary" onClick={this.props.onLoadMore}>
              Load more content...
            </Button>                
          </Grid>
        }
      </React.Fragment>
    );
  }
}

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: 20,
  },
  table: {
    minWidth: 1020,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  listItemWrapper: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'flex-start',
    flexDirection: 'column',    
  },
  listItemHeader: {
    height: 62,
    '& h3': {
      lineHeight: 1,
    }
  },
  expandIcon: {
    marginRight: 4,
    opacity: .5,
  },
  collapseWrapperInner: {
    backgroundColor: '#fafafa !important',
  },
  stickyItem: {
    zIndex: 10,
    marginTop: 64,
    width: '100%',
    minHeight: 62,
    height: 62,
    backgroundColor: '#fff',
    //borderBottom: 'solid 1px rgba(0, 0, 0, 0.12)',
    [theme.breakpoints.down('xs')]: {
      marginTop: 56,
    },
  },
  deleteBtn: {
    margin: 4,
  },
  alertBtn: {
    color: theme.palette.getContrastText(red[600]),
    backgroundColor: red[600],
    '&:hover': {
      backgroundColor: red[700],
    },
  },
  linkReset: {
    color: 'inherit',
    textDecoration: 'inherit'
  },
  iconSmall: {
    fontSize: 22,
  },
  infoBox: {
    borderRadius: 0,
    backgroundColor: '#ffebee',
    padding: '12px 24px', 
    border: 'solid 1px rgba(0,0,0,.05)'
  },
  infoText: {
    color: '#f44336',
  },
  menuPaper: { 
    maxHeight: 360 
  },
  disabledInfo: {
    position: 'fixed',
    top: '50%',
    left: 'calc(50% + 36px)',
    transform: 'translate(-50%, -50%)',
  },
  '@global .open-sidebar .disabled-info': {
    left: 'calc(50% + 120px)',
  },
  shopifyId: {
    marginRight: 12, 
    float: "right", 
    border: "none", 
    fontWeight: 'normal !important'
  }
});

ItemsList.propTypes = {
  classes: PropTypes.object.isRequired,
};
ItemsList.defaultProps = {
  isSticky: true,
  paginate: true,
  allowFieldFiltering: false,
}

const mapStateToProps = ({ shop, themes, languages }) => ({
  shop: shop,
  themes: themes,
  languages: languages,
  editLanguage: shop.shop.edit_language
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
    },
    dispatch
  )

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withTranslation()(withSnackbar(ItemsList)))));

